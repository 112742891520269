const secrets = {
  EMAILJS_SERVICE_ID: "service_ne1er5y",
  EMAILJS_TEMPLATE_ID: "template_6dj25zt",
  EMAILJS_USER_ID: "user_lNHcQCEkB4COZq8xUogDX",

  CONTACT_EMAIL: "roland.strasser.01@gmail.com",
  LINK_GITHUB: "https://github.com/rol1510",
  LINK_LINKEDIN: "https://www.linkedin.com/in/roland-strasser-9621a5220/",

  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyDRIjkCsgI3iiWYJurXmDGy5bcS9Y4VBtc",
    authDomain: "personalpage-10c9d.firebaseapp.com",
    databaseURL:
      "https://personalpage-10c9d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "personalpage-10c9d",
    storageBucket: "personalpage-10c9d.appspot.com",
    messagingSenderId: "251640634883",
    appId: "1:251640634883:web:83768947f60cb03a363836",
    measurementId: "G-YML59MPLP5",
  },
};

export default secrets;
